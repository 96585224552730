var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("table-group", {
        ref: _vm.tableRef,
        attrs: {
          filterOn: { byAttribute: { id: this.resourceId } },
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          noActions: "",
          noCustom: "",
          noInnerWidth: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }